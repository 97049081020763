body {
  background-color: white;
  color: #352391;
}

.containers {
  display: flex;
  margin: 10px
}

.container {
  width: 100%;
  height: auto;
  /* color: black; */
  padding: 10px;
}

.card-title {
  color: black;
  font-size: 35px;

}

.card-text {
  color: black;
  font-size: 15px;
  padding-Right: 180px;
}

.custom-card {
  background-color: transparent;
  border: none;
}

.table-container {
  flex: 2;
  max-width: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(37, 37, 37);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888888;
}

.card-container {
  border-radius: 10px;
  margin-left: 20px;
  padding: 5px;
  max-width: 100%;
  margin-bottom: 40px;
  height: 68vh;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: rgb(70, 69, 69);
  color: white;
  /* border: 1px solid #000000; */
}

.table {
  width: 100%;
  font-size: 12px;
}

.table th,
.table td {
  padding: 8px;
}

.small-btn {
  padding: 2px 6px;
  font-size: 12px;
  margin: 0 2px;
}

/* .table-bordered {
  border: 1px solid #000000;
} */

.table-hover tbody tr:hover {
  background-color: #e6e6e6;
}

.table-primary {
  background-color: #007bff;
  color: #fff;
}

th {
  background-color: rgb(97, 160, 211) !important;
}

.table-success {
  animation: blink 1s infinite;
}

.table-info {
  animation: blink 2s infinite reverse;
}

button {
  margin: 5px;
}

@keyframes blink {
  0% {
    background-color: #c3e6cb;
  }

  50% {
    background-color: #ee7680;
  }

  100% {
    background-color: #c3e6cb;
  }
}