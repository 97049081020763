/* Navbar.css */
.navbar {
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
  font-size: 24px;
  font-weight: bold;
}

.add-emp-btn {
  margin-left: auto;
}

.dropdown {
  margin-right: 20px;
}

.dropdown-menu {
  flex-direction: column;
}

.dropdown-menu .dropdown-item {
  width: 100%;
}
